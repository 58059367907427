
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getBatches, submitBatch } from "@/api/batch.api";
import { copyText } from "vue3-clipboard";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";

export interface Data {
  items: [];
}

export default defineComponent({
  name: "Batch Queue",
  components: {},
  setup() {
    const router = useRouter();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({ items: [] });

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Claim Management");
      data.items = await getBatches();
    });

    function openBatch(batch) {
      router.push({
        name: "claimsBatchPage",
        params: {
          batchId: batch.id,
        },
      });
    }

    async function submit(batch) {
      await submitBatch(batch.id);
      Swal.fire("Ok!", "Batch was submitted", "success");
    }

    async function copy(batch) {
      let fullText = "";
      batch.batchItems.forEach((batchItem) => {
        fullText += batchItem.serializedData;
        fullText += "\n";
      });
      copyText(fullText, undefined, (error, event) => {
        if (error) {
          alert("Can not copy");
          console.log(error);
        } else {
          alert("Copied");
          console.log(event);
        }
      });
    }

    return {
      copy,
      openBatch,
      organizationId,
      submit,
      data,
    };
  },
});
